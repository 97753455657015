
import Swiper from 'swiper';
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  Controller,
  Thumbs,
  EffectCreative,
  EffectFade,
  //EffectCoverflow
} from 'swiper/modules';
import { gsap } from "gsap";

Swiper.use([Navigation, Autoplay, Pagination, Scrollbar, Controller, EffectCreative]);
/// slider banner
export function swipeBan(slider = ".hero-slider .swiper-container", controlby = "", num = false) {
    var carrousel = document.querySelector(slider);
    var control_slid = document.querySelector(controlby);
    let swiper;
    let swiperControl;
    if (carrousel) {
         swiper = new Swiper(slider, {
            modules: [EffectFade],
            loop: true,
            speed: 1150,
            spaceBetween: 0,
            slidesPerView: 1,
            parallax: true,
            control:'nav-slide-item',
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: 'true'
            },
            // Responsive breakpoints
            on: {
                transitionStart: function(){
                    var videos = document.querySelectorAll('.hero-slider video');
                    if (videos){
                        Array.prototype.forEach.call(videos, function(video){
                            video.pause();
                        });
                    }
                },

                transitionEnd: function(){
                    var activeIndex = this.activeIndex;
                    var activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                    var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                    if (activeSlideVideo) {
                        activeSlideVideo.play();
                    }
                },

            }
        });


        if (control_slid) {
           swiperControl = new Swiper(control_slid, {
              //allowTouchMove:false,
              modules: [Thumbs],
              loop: true,
              spaceBetween: 10,
              slidesPerView: 3,
              navigation: true,
              //autoplay:5000,
              //autoplay: {
              //    delay: 5000,
              //},
              // Navigation arrows
              navigation: {
                  nextEl: '#head-next',
                  prevEl: '#head-prev'
              },
              thumbs: {
                swiper: swiper,
              },
              //autoplay:5000,
              //autoplay: {
              //    delay: 5000,
             //}
              
          }
        );
      }
    }

}


export function swipeAnim(slider = ".hero-slider .swiper-container", num = false) {
  const not_active_slide_scale_value = 0.5;
  const not_active_slide_opacity_value = 0.2;

  const hoverSwiper = new Swiper('.slider-img-z', {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 800,
    effect: 'creative',
    creativeEffect: {
      //limitProgress: 2, // slides after 2nd before/after active will have same state
      prev: {
        opacity: not_active_slide_opacity_value,
        scale: not_active_slide_scale_value,
        // will set `translateX(-90%)` on previous slides
        translate: ["-90%", 0, 0],
      },
      next: {
        opacity: not_active_slide_opacity_value,
        scale: not_active_slide_scale_value,
        // will set `translateX(90%)` on next slides
        translate: ["90%", 0, 0],
      },
    },
    mousewheel: {
      invert: false,
      eventsTarged: '.slider-carousel-holder',
    },
    breakpoints: {
      640: {
        // slidesPerView: 1.2,
      },
    },

    //   autoplay: {
    //     delay: 5000,
    //   },
  })

  const imgSwiper = new Swiper('.slider-img-carousel', {
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 800,
      mousewheel: {
        invert: false,
        eventsTarged: '.slider-carousel-holder',
      },
      breakpoints: {
        640: {
          // slidesPerView: 1.2,
          spaceBetween: 30,
        },
      },
      //   autoplay: {
      //     delay: 5000,
      //   },
      navigation: {
        nextEl: '.slider-carousel-next',
        prevEl: '.slider-carousel-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    const contentSwiper = new Swiper('.slider-content-carousel', {
      slidesPerView: 1,
      spaceBetween: 30,
      effect: 'fade',
      fadeEffect: {
        crossFade: true, // added(resolve the overlapping of the slides)
      },
      // parallax: true
    })

    imgSwiper.controller.control = [contentSwiper,hoverSwiper]
    contentSwiper.controller.control = [imgSwiper,hoverSwiper]

    let content
    //if (document.querySelectorAll('.slider-content-carousel') ) {document.querySelectorAll
      if ( document.querySelectorAll('.slider-content-carousel').length > 0 ) {
          content = contentSwiper.el.querySelectorAll('.content')

      imgSwiper.on('slideNextTransitionStart', function (e) {
        if (document.querySelectorAll('.slider-content-carousel') ) {
        const { previousIndex, realIndex } = e
        let outgoingText = content[previousIndex].querySelector('h2')
        let incomingText = content[realIndex].querySelector('h2')
        let paragrapgh = content[previousIndex].querySelector('p')
        let outgoingAnchor = content[previousIndex].querySelector('a')
        let incomingAnchor = content[realIndex].querySelector('a')

        let previousSlideIndex = imgSwiper.previousIndex
        let previousSlide = imgSwiper.slides[previousSlideIndex]
        let img = previousSlide.querySelector('img')

        let timeline = gsap.timeline({
          onComplete: function () {
            gsap.set([outgoingText, incomingText, img, outgoingAnchor, incomingAnchor, paragrapgh], { clearProps: 'all' })
          },
        })
        timeline
          .to(img, { scale: 0.9 })

          .to(outgoingText, { y: '100%', autoAlpha: 1 }, '<')
          .to(outgoingAnchor, { y: '100%' }, '<')
          .from(incomingText, { y: '-100%', autoAlpha: 0 }, '-=0.25')
          .from(incomingAnchor, { y: '-100%' }, '<')
          .to(paragrapgh, { autoAlpha: 0 })
        }
      })

      imgSwiper.on('slidePrevTransitionStart', function (e) {
        if (document.querySelectorAll('.slider-content-carousel') ) {
        const { previousIndex, realIndex } = e
        let outgoingText = content[previousIndex].querySelector('h2')
        let incomingText = content[realIndex].querySelector('h2')
        let paragrapgh = content[previousIndex].querySelector('p')
        let outgoingAnchor = content[previousIndex].querySelector('a')
        let incomingAnchor = content[realIndex].querySelector('a')

        let currentActiveSlideIndex = imgSwiper.activeIndex
        let currentActiveSlide = imgSwiper.slides[currentActiveSlideIndex]
        let img = currentActiveSlide.querySelector('img')

        let timeline = gsap.timeline({
          onComplete: function () {
            gsap.set([outgoingText, incomingText, img, outgoingAnchor, incomingAnchor, paragrapgh], { clearProps: 'all' })
          },
        })

        timeline
          .from(img, { scale: 0.8 })
          .to(outgoingText, { y: '-100%', autoAlpha: 0 }, '<')
          .to(outgoingAnchor, { y: '-100%' }, '<')
          .from(incomingText, { y: '100%', autoAlpha: 1 }, '-=0.25')
          .from(incomingAnchor, { y: '100%' }, '<')
          .from(paragrapgh, { autoAlpha: 0 })

        }
      })
    }

}
